@tailwind base;
@tailwind components;
@tailwind utilities;


.MuiDataGrid-columnHeaderTitle {
    font-family: "DM Sans", serif;
}

.MuiDataGrid-columnHeader {
    background: rgba(223, 237, 255, 1);
}


.MuiDataGrid-columnHeaderTitle {
    color: rgba(24, 40, 61, 1);
    font-weight: 600 !important;
}

.error {
    color: red;
    font-size: 14px;
}

.input-bg {
    background: rgba(241, 246, 255, 1);
}

.request-view-table tr {
    border: 1px solid rgba(228, 237, 255, 1);
}

.request-view-table tr td {
    border: 1px solid rgba(228, 237, 255, 1);
    padding: 15px 30px;
    width: 50%;
}

.category-list {
    line-height: 55px;
}

.category-list li {
    text-decoration: underline;
    cursor: pointer;
}


/* Request details */

.details-info .tab-list {
    display: flex;
    color: #353F4F;
    gap: 40px;
}

.details-info .tab-list li {
    cursor: pointer;
    font-size: 14px;
    padding: 10px 10px 10px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.details-info .tab-list li.active {
    color: rgba(34, 96, 217, 1);
    font-weight: 600;
}

.details-info .tab-list li.active span {
    background: rgba(34, 96, 217, 1);
    display: inline-block;
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: -1px;
    left: 0;
    border-radius: 30px 30px 0px 0px;
}

.details-info .tab-list .total-proram-count {
    background: #D6D6D6;
    border-radius: 4px;
    padding: 3px 6px;
}

.details-info .tab-list .total-proram-count.active {
    background: #EEF5FF;
    color: #1D5BBF;
}



.post-comments .add-comments {
    display: flex;
    gap: 30px;
    margin: 30px 0 50px;
}

.post-comments .add-comments img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.post-comments .add-comments .comment-input {
    border-bottom: 1px solid rgba(234, 236, 235, 1);
    width: 100%;
}

.add-comments .comment-input:focus,
.feed-details .feed-info .post-comments .add-comments .comment-input:focus-visible {
    border-bottom: 1px solid rgba(234, 236, 235, 1);
    outline: none;
}

.post-list-comments {
    padding: 15px 0;
    display: flex;
    gap: 10px;
}


.post-list-comments .count-content {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    color: rgba(29, 91, 191, 1);
    font-size: 11px;
    padding: 10px;
    border-radius: 4px;
}

.post-list-comments .user-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}


.left-side-bar .active{
    background-color: rgba(240, 245, 255, 1);
    border-right-width: 6px;
    border-color: rgba(34, 96, 217, 1);
}

.permission-table thead tr{
    background: rgba(240, 245, 255, 1);
}

.permission-table thead tr th {
    padding: 16px;
    text-align: left;
}

.permission-table tbody tr td {
    padding: 10px;
}

.permission-table {
    width: 100%;
}

.permission-table, .permission-table tr {
    border: 1px solid rgba(230, 230, 230, 1);
    border-radius: 10px;
}

.custom-shadow{
    box-shadow: 4px 4px 25px 0px #00000026;
}

.menu-before::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: -6px;
    left: 14px;
    width: 10px;
    height: 10px;
    background-color: white;
    border-top: 1px solid #C6D9FF;
    border-left: 1px solid #C6D9FF;
    transform: rotate(45deg); 
  }

  .hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none; 
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    display: none; 
  }

  .rbc-month-header{
    height: 60px;
    /* justify-content: center;
    display: flex;
    align-items: center; */

  }

  .rbc-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500 !important;
    font-family: "DM Sans", serif;
    font-size: 16px;
    color: #353F4F;
  }

  .truncate-3-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .truncate-1-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
/* 
  .rbc-date-cell {
    height: 100px;
  } */
  


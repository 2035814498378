.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dashed-borderd {
  position: relative;
}

.dashed-borderd::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 2px;
  background-image: repeating-linear-gradient(
    to bottom,
    #929292 0%,
    #929292 12px,
    transparent 12px,
    transparent 21px
  );
}
/* Styling the select input */
select {
  width: 100%;
  padding: 0.32rem 1rem;
  font-size: 14px;
  line-height: 2.15;
  background-color: #f1f1f1; /* Change as needed */
  color: #232323;
  border-radius: 3px;
  height: 60px;
  border: none;
  outline: none;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

select:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Focus shadow */
}

.premium {
  color: #d1a639;
}

.rbc-event {
  height: 100%;
  background-color: unset !important;
}

.rbc-event-content {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px;
  height: 100%;
}

.CalendarStylesWrapper {
  padding: 2.5rem;
  margin-top: 0rem;
  padding-left: 1rem;
}

.CalendarStylesWrapper .rbc-calendar {
  padding: 0.5rem;
  background-color: var(--calendar-bg-color);
  border-radius: 3px;
}

.CalendarStylesWrapper .rbc-calendar .rbc-toolbar .rbc-btn-group .rbc-active {
  background-color: transparent !important;
  box-shadow: none !important;
  border-bottom: 2px solid var(--primary-color) !important;
}

.CalendarStylesWrapper .rbc-calendar .rbc-toolbar .rbc-btn-group button {
  border-radius: 0 !important;
  cursor: pointer;
  border: none !important;
}

.CalendarStylesWrapper .rbc-calendar .rbc-toolbar .rbc-btn-group button:hover {
  background-color: transparent !important;
}

.CalendarStylesWrapper .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
  font-weight: 600;
}

.CalendarStylesWrapper .rbc-calendar .rbc-time-view .rbc-allday-cell {
  display: none;
}

.CalendarStylesWrapper .rbc-calendar .rbc-time-view .rbc-time-content {
  border-top: 1px solid #ddd !important;
}

.CalendarStylesWrapper
  .rbc-calendar
  .rbc-time-view
  .rbc-time-content
  .rbc-label {
  font-size: 13px;
  color: var(--primary-color);
}

.CalendarStylesWrapper .rbc-calendar .rbc-day-slot .rbc-event-label {
  display: none;
}

.CalendarStylesWrapper .rbc-calendar .rbc-month-view {
  overflow-y: scroll;
}

.CalendarStylesWrapper .rbc-calendar .rbc-month-row {
  overflow: visible;
}

.CalendarStylesWrapper .rbc-calendar .rbc-month-row .rbc-row-content {
  display: flex;
  flex-direction: column-reverse;
}

.CalendarStylesWrapper .rbc-calendar .rbc-month-row .rbc-row-segment {
  position: relative;
  padding: 10px;
}

.CalendarStylesWrapper
  .rbc-calendar
  .rbc-month-row
  .rbc-row-segment
  .rbc-event-content {
  width: 100%;
}

.CalendarStylesWrapper
  .rbc-calendar
  .rbc-month-row
  .rbc-row-segment
  .rbc-show-more {
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 40px;
  color: black;
  background-color: #ffce47;
}

.CalendarStylesWrapper .rbc-calendar .rbc-month-row .rbc-today {
  background-color: transparent;
}

.CalendarStylesWrapper .rbc-calendar .rbc-time-header-cell {
  min-height: 10vh !important;
}

.CalendarStylesWrapper .rbc-calendar .rbc-time-header-cell .rbc-header {
  display: flex;
  justify-content: center;
}

.CalendarStylesWrapper
  .rbc-calendar
  .rbc-time-header-cell
  .rbc-header
  .rbc-button-link {
  font-size: 13px;
  font-weight: 600;
}

.CalendarStylesWrapper .rbc-calendar .rbc-day-slot .rbc-events-container {
  margin-right: 2px;
}

.CalendarStylesWrapper
  .rbc-calendar
  .rbc-day-slot
  .rbc-events-container
  .rbc-event
  .rbc-event-content {
  width: max-content;
}

.CalendarStylesWrapper .rbc-calendar .rbc-now .rbc-button-link {
  width: 30px;
  height: 30px;
  border-radius: 40px;
  color: var(--white-color);
  background-color: var(--primary-color);
}

.CalendarStylesWrapper .rbc-calendar .rbc-event {
  padding: 0;
  overflow: visible;
  background-color: transparent;
  border: none;
  height: auto;
}

.CalendarStylesWrapper .rbc-calendar .rbc-event .rbc-event-content {
  z-index: 1;
  height: auto;
  border-radius: 0 4px 4px 0;
  /* box-shadow: 4px 4px 25px 0px #00000026; */
}

.CalendarStylesWrapper .rbc-calendar .rbc-event:focus {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.request-table {
  border: 1px solid #353f4f;
}

.request-table option {
  height: 45px;
}